/*eslint-disable*/ module.exports = {
  messages: {
    '* Vyberte vše, co platí.': '* Vyberte vše, co platí.',
    '* potvrďte prosím souhlas': '* potvrď prosím souhlas',
    'Ale nezapomeň, tohle stále jen začátek. Moc ti držíme palce v dalším budování tvého nápadu. Dej nám vědět, jak ti to jde na hello@startupbox.cz.':
      'Ale nezapomeň, tohle stále jen začátek. Moc ti držíme palce v dalším budování tvého nápadu. Dej nám vědět, jak ti to jde na hello@startupbox.cz.',
    'Archived projects': 'Archivované nápady',
    'Celé jméno': 'Celé jméno',
    'Chybně zadané heslo nebo email': 'Chybně zadané heslo nebo email',
    'Co je StartupBox?': 'Co je StartupBox?',
    'Co nejlépe popisuje tvou aktuální situaci?':
      'Co nejlépe popisuje tvou aktuální situaci?',
    'Co získáš': 'Co získáš',
    'Další kapitola': 'Další kapitola',
    Dashboard: 'Přehled',
    'Deník projektu': 'Deník nápadu',
    Dokončeno: 'Dokončeno',
    Dokončit: 'Dokončit',
    'Doporučeno přímo tobě': 'Doporučeno přímo tobě',
    Doporučujeme: 'Doporučujeme',
    'Feedback:': 'Zpětná vazba:',
    Finance: 'Finance',
    'Fáze projektu': 'Fáze nápadu',
    'Garant sekce': 'Garant sekce',
    Grafika: 'Grafika',
    'Gratulujeme!': 'Gratulujeme!',
    HR: 'HR',
    Heslo: 'Heslo',
    'Heslo bylo změněno': 'Heslo bylo úspěšně změněno',
    'Heslo musí mít alespoň 6 znaků.': 'Heslo musí mít alespoň 6 znaků.',
    Investice: 'Investice',
    'Jak budeš Deníkem procházet, budeme ti ukazovat, kde se nacházíš a kolik ti ještě zbývá.':
      'Jak budeš kapitoly postupně dokončovat, budeme ti ukazovat, kde se s Deníkem nacházíš a kolik ti ještě zbývá.',
    'Jak jsi daleko': 'Tvůj postup',
    'Jak jsi na tom se zákazníky?': 'Jak jsi na tom se zákazníky?',
    'Jak to vlastně funguje a co zde najdete?':
      'Jak Deník nápadu vlastně funguje a co zde najdeš?',
    'Jakmile budeš s kapitolou hotov, označ si ji jako dokončenou. Uvidíš tak hezky svůj posun kupředu.':
      'Jakmile máš kapitolu hotovou, označ si ji jako dokončenou. Uvidíš tak hezky svůj posun kupředu.',
    'Je super, že jsi se do toho pustil. Teď nám prosím řekni něco málo o sobě. Pamatuj, čím upřímnější a specifičtější budeš, tím větší máš šanci na napojení na ty správné lidi a investory, kteří ti pomůžou s rozjezdem. Neboj, nezabere to více, než pár minut.':
      'Je super, že jsi se do toho pustil. Teď nám prosím řekni něco málo o sobě. Pamatuj, čím upřímnější a specifičtější budeš, tím větší máš šanci na napojení na ty správné lidi a investory, kteří ti můžou pomoct s rozjezdem. Neboj, nezabere to více, než pár minut.',
    'Ještě nemáte účet?': 'Ještě nemáte účet?',
    Jiný: 'Jiný',
    Jméno: 'Jméno',
    'Jméno projektu': 'Název nápadu',
    'Jupí, tvůj Deník je kompletně hotový! Skvělá práce! Celý tým StartupBoxu ti od srdce gratuluje!':
      'Jupí, tvůj Deník je kompletně hotový! Skvělá práce! Celý tým StartupBoxu ti od srdce gratuluje!',
    Kapitola: 'Obsah kapitoly',
    'Každá kapitola se skládá ze tří částí: video s podnikateli, následně špetka teorie a potom konkrétní nástroje a kroky, co pro splnění udělat.':
      'Každá kapitola se skládá ze tří částí: proč dané téma řešit, následně špetka teorie a potom konkrétní nástroje a kroky, co pro splnění kapitoly udělat.',
    Komunita: 'Komunita na FB',
    'Kód pro obnovení z emailu': 'Kód z emailu pro obnovení',
    'MVP již mám a chci ho vylepšit': 'MVP již mám a chci ho vylepšit',
    Management: 'Management',
    Marketing: 'Marketing',
    'Moje Odznaky': 'Moje Odznaky',
    'Moje Poznámky': 'Moje Poznámky',
    'Moje projekty': 'Moje nápady',
    'Mám hotový produkt, ale zákazníci o něj nemají zájem':
      'Mám hotový produkt, ale zákazníci o něj nemají zájem',
    'Mám nápad a chci na něm zapracovat': 'Mám nápad a chci na něm zapracovat',
    'Mám nápad, o kterém teď přemýšlím': 'Mám nápad, o kterém teď přemýšlím',
    'Mám platící zákazníky': 'Mám platící zákazníky',
    'Mám produkt, platící zákazníky a teď chci se svým startupem vyrůst':
      'Mám produkt, platící zákazníky a teď chci se svým startupem vyrůst',
    'Mám prototyp produktu (vizualizace, modely atp.)':
      'Mám prototyp produktu (vizualizace, modely atp.)',
    'Mám prototyp, který je potřeba vyzkoušet':
      'Mám prototyp, který je potřeba vyzkoušet',
    'Mám první verzi produktu (MVP), kterou teď vylepšuji':
      'Mám první verzi produktu (MVP), kterou teď vylepšuji',
    'Můj profil': 'Můj profil',
    Nahrávání: 'nahrávání...',
    'Najít investora': 'Najít investora',
    'Najít mentora': 'Najít mentora',
    'Najít nápad, na kterém budu pracovat':
      'Najít nápad, na kterém budu pracovat',
    'Najít spoluzakladatele': 'Najít spoluzakladatele',
    'Nastavit nové heslo': 'Nastavit nové heslo',
    Networking: 'Networking',
    'Nové Heslo': 'Nové Heslo',
    'Nové heslo *': 'Nové heslo *',
    'Nový projekt': 'Nový nápad',
    'Nyní se můžete přihlásit s novým heslem':
      'Nyní se můžeš přihlásit s novým heslem',
    Náplň: 'Doporučeno přímo tobě',
    'Název projektu': 'Název nápadu',
    'Něco se pokazilo': 'Ups, něco se nepovedlo.',
    'Něco se pokazilo, zkuste prosím stránku obnovit':
      'Ups, něco se pokazilo, zkus prosím stránku načíst znovu.',
    'Něco se pokazilo, zkuste to prosím znovu':
      'Něco se pokazilo, zkuste to prosím znovu',
    Obchod: 'Obchod',
    'Obnovení hesla': 'Obnovení hesla',
    'Obnovit heslo': 'Obnovit heslo',
    Odborníci: 'Odborníci',
    Odeslat: 'Odeslat',
    Odesláno: 'Odesláno',
    'Odesílání...': 'Odesílání…',
    'Odhlásit se': 'Odhlásit se',
    'Opravdu chcete projekt {projectName} smazat?': [
      'Opravdu chceš tvůj nápad ',
      ['projectName'],
      ' nevratně smazat?',
    ],
    'Opravdu chcete smazat svůj profil?':
      'Opravdu chceš nevratně smazat tvůj profil?',
    'Opravdu chci projekt smazat': 'Opravdu chci nápad smazat',
    'Opravdu chci svůj profil smazat': 'Opravdu chci svůj profil smazat',
    'Ověření dokončeno': 'Ověření dokončeno',
    'Podle toho, v jaké jsi fázi, ti zvýrazňujeme některé kapitoly. I když už jsi dál, doporučujeme nepřeskakovat předchozí fáze. Mohl/a jsi něco velmi důležitého přeskočit.':
      'Podle tvých předchozích odpovědí jsme ti na míru zvýraznili kapitoly, které nepřeskakuj. I když máš pocit, že jsi dál. Je to důležité pro tvůj nápad.',
    Pokračovat: 'Pokračovat',
    Pokračování: 'Zaznamenej tvůj pokrok',
    'Pokud chceš, piš si poznámky k jednotlivým tématům. Srovnání myšlenek i možnost exportu se může hodit pro potenciální investory i spoluzakladatele.':
      'Doporučujeme, piš si poznámky k jednotlivým tématům. Srovnání myšlenek i možnost exportu (připravujeme) se může hodit pro potenciální investory i spoluzakladatele.',
    'Pokud už účet máte, stačí se': 'Pokud už účet máte, stačí se',
    'Poslední update': 'Poslední úprava',
    Použít: 'Použít',
    Poznámky: 'Tvé poznámky',
    'Pravidelně získávám zpětnou vazbu (ať na hotový či připravovaný produkt)':
      'Pravidelně získávám zpětnou vazbu (ať na hotový či připravovaný produkt)',
    'Produkt mám, ale zatím žádný zákazník':
      'Produkt mám, ale zatím žádný zákazník',
    'Produktový management': 'Produktový management',
    'Profil partnera': 'Profil partnera',
    'Profilová fotka': 'Profilová fotka',
    'Programování (Backend)': 'Programování (Backend)',
    'Programování (Frontend)': 'Programování (Frontend)',
    'Projekt byl smazán': 'Nápad byl smazán',
    'Prosím vyplň základní informace o projektu. Díky nim dokážeme personalizovat doporučení a napojit na relevantní partnery.':
      'Prosím vyplň základní informace o tvém nápadu. Díky nim pro tebe dokážeme vybrat nejdůležitější kapitoly, případně tě napojit na relevantní partnery.',
    'První krok': 'První krok',
    'První krok jste udělali pomocí StartupBoxu a získali jste první odznak. Pokračujte v této cestě a sbírejte další odznaky.':
      'Super! Udělal jsi první krok a získal tak první odznak. Pokračuj ve tvé cestě a sbírej další odznaky.',
    Právo: 'Právo',
    'Průvodce má čtyři fáze, kterými tě provází. Od vymýšlení nápadů až k rozjezdu firmy.':
      'Průvodce má několik fází, kterými tě provází. Od vymýšlení nápadů až k rozjezdu firmy.',
    'Předchozí kapitola': 'Předchozí kapitola',
    'Přejít na Dashboard': 'Vstoupit do aplikace',
    'Přejít na Dashboard po smazání': 'Přejít na přehled',
    Přidat: 'Přidat',
    'Přidat projekt': 'Přidat nápad',
    Přihlašování: 'přihlašování…',
    'Přihlásit se': 'Přihlásit se',
    Příjmení: 'Příjmení',
    Registrace: 'Registrace',
    'Registrace s tímto emailem se nezdařila':
      'Registrace s tímto e-mailem se nezdařila',
    'Registrací souhlasím s <0>podmínkami použití</0> a <1>zásadami ochrany osobních údajů</1>.':
      'Registrací souhlasím s <0>podmínkami použití</0> a <1>zásadami ochrany osobních údajů</1>.',
    'Registrovat se': 'Registrovat se',
    'Rozjeď svůj STARTUP i bez nápadu | online aplikace Startupbox':
      'Hledáte nápad nebo cestu, jak rozjet váš Startup? | Online průvodce StartupBox',
    'Sleduj tvůj pokrok': 'Sleduj tvůj pokrok',
    'Smazat profil': 'Smazat profil',
    'Smazat projekt': 'Smazat nápad',
    'Speciální nabídka': 'Speciální nabídka',
    'Speciální nabídky pro startupy': 'Speciální nabídky pro startupy',
    'Stránka neexistuje': 'Stránka neexistuje',
    'Stránka nenalezena': 'Stránka nenalezena',
    'Tato akce je nevratná a příjdete tak o všechny uložené poznámky. Pokud chcete projekt skutečně smazat, zadejde prosím níže název projektu':
      'Tato akce je nevratná a příjdeš tak o všechny uložené poznámky. Pokud chceš nápad skutečně smazat, opiš prosím níže název tvého nápadu',
    'Tato akce je nevratná a příjdete tak o všechny vaše projekty a uložné poznámky. Pokud chcete Váš účet skutečně smazat, zadejde prosím níže celé Vaše jméno (Křestní jméno a příjmení)':
      'Tato akce je nevratná a příjdeš tak o všechny tvé nápady i uložené poznámky. Pokud chceš tvůj účet skutečně smazat, zadej prosím níže celé tvé jméno (křestní jméno a příjmení)',
    'Tvoje schopnosti': 'Tvoje schopnosti',
    'UX/UI Design': 'UX/UI Design',
    'Ukládání změn...': 'Ukládání změn…',
    'Uložit změny': 'Uložit změny',
    'Upravit projekt': 'Upravit nápad',
    'Vyber kraj': 'Vyber kraj',
    'Vyber, co umíš a v jakých oblastech se cítíš silný. Čím upřímnější budeš, tím lépe využiješ StartupBox.':
      'Vyber, co umíš a v jakých oblastech se cítíš silný. Čím upřímnější budeš, tím lépe využiješ StartupBox.',
    'Vyberte prosím alespoň jednu možnost':
      'Vyber prosím alespoň jednu možnost',
    'Vytvořit jiný projekt': 'Vytvořit jiný nápad',
    'Vytvořit profil': 'Vytvořit profil',
    'Vytvořit projekt': 'Vytvořit nápad',
    'Vytvořit účet': 'Vytvořit účet',
    'Vytváření profilu...': 'Vytváření profilu…',
    'Vytváření projektu...': 'Vytváření nápadu…',
    'Vyzkoušej ZDARMA online aplikaci Startupbox a rozjeď svůj projekt. Poradíme, co je důležité i BEZ nápadu a jak ho následně najít. Napojíme tě na investory.':
      'Online průvodce StartupBox je a bude zdarma. Rozjeď svůj projekt, staň se součástí komunity, nevymýšlej znovu kolo. Přidej se.',
    'Váš email': 'Váš email',
    'Váš email byl úspěšně ověřen': 'Tvůj e-mail byl úspěšně ověřen',
    Více: 'Zobrazit více',
    'Vítej ve StartupBoxu!': 'Vítej ve StartupBoxu!',
    'Vítej ve startupbox': 'Vítej ve StartupBoxu',
    'Výhody a slevy': 'Výhody a slevy',
    'Za zákazníkem půjdu hned jak bude můj produkt hotový':
      'Za zákazníkem půjdu hned jak bude můj produkt hotový',
    'Zadejte prosím emailovou adresu se kterou jste se registrovali a zašleme vám link pro obnovení hesla':
      'Zadej prosím emailovou adresu, se kterou jsi se registroval a my ti zašleme link pro obnovení hesla',
    'Zadejte prosím heslo znovu*': 'Zadej prosím heslo znovu*',
    'Založil jsem startup a chci z něj dostat to nejlepší':
      'Založil jsem startup a chci z něj dostat to nejlepší',
    'Zapomenuté heslo': 'Zapomenuté heslo',
    'Zapomenuté heslo?': 'Zapomenuté heslo?',
    'Zaregistrujte se a získejte zdarma přístup k dalšímu obsahu':
      'Zaregistruj se a získej přístup k dalšímu obsahu',
    'Zatím nemám nápad, ale chci ho najít':
      'Zatím nemám nápad, ale chci ho najít',
    'Zatím nemáte žádné projekty. Začněte svou podnikatelskou cestu hned teď!':
      'Zatím nemáš založený žádný deník nápadu. Začni svou cestu hned teď!',
    Zavřít: 'Zavřít',
    'Zjistit, proč můj produkt nechce nikdo kupovat':
      'Zjistit, proč můj produkt nechce nikdo kupovat',
    'Zkontroluj prosím tvoji e-mailovou schránku, poslali jsme ti potvrzovací e-mail, abychom měli jistotu, že je to tvůj e-mail. Stačí kliknout na odkaz v e-mailu.':
      'Zkontroluj prosím tvoji e-mailovou schránku, poslali jsme ti potvrzovací e-mail. Stačí kliknout na odkaz.',
    Změnit: 'Změnit',
    'Změny byli uloženy': 'Změny byly uloženy',
    'Změny uloženy': 'Změny uloženy',
    'Zpět na přihlášení': 'Zpět na přihlášení',
    'Zákaznická podpora': 'Zákaznická podpora',
    'Získat informace o tom, jak úspěšně podnikat':
      'Získat informace o tom, jak úspěšně podnikat',
    benefit1:
      'Ucelený návod, jak ověřit smysluplnost nápadu, včetně konkrétního obsahu',
    benefit2: 'Pro každý úkol budeš mít k dispozici praktické nástroje',
    benefit3:
      'Odborníky a mentory budeš mít na dosah své ruky, oslovit je můžeš rovnou z aplikace',
    'hesla nejsou stejná': 'hesla nejsou stejná',
    'heslo musí mít minimálně 6 znaků': 'heslo musí mít minimálně 6 znaků',
    kraj: 'kraj',
    'mažeme to': 'mazání…',
    menu: 'menu',
    'můj účet': 'můj účet',
    'nastavování...': 'nastavování…',
    nebo: 'nebo',
    obor: 'obor',
    'potřeba zadat': 'potřeba zadat',
    'přejít na přihlášení': 'přejít na přihlášení',
    přihlásit: 'přihlásit',
    'zadejte prosím email v platném formátu':
      'zadej prosím e-mail v platném formátu',
    zavřít: 'zavřít',
    'zobrazit vše': 'zobrazit vše',
    zpět: 'zpět',
    'zpět do projektu': 'zpět na přehled',
    'zpět k úkolu': 'zpět',
    '{buttonLabel}': [['buttonLabel']],
    '{label}': [['label']],
    'Úprava profilu': 'Úprava profilu',
    'Čeho chceš pomocí StartupBoxu docílit?':
      'Čeho chceš pomocí StartupBoxu docílit?',
    'Čeho se ještě snažíte dosáhnout?': 'Čeho se ještě snažíte dosáhnout?',
  },
};
