// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
import { GA_TRACKING_ID } from '../constants';

type Event = {
  action: string;
  category?: string;
  label: string;
  value: string;
};

const pageView = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({ action, category, label, value }: Event) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const ga = {
  pageView,
  event,
};
