import { t } from '@lingui/macro';
export const GA_TRACKING_ID = 'G-WTGPZBYSWE';
export const GRAPH_CMS_API_URL = process.env.NEXT_PUBLIC_GRAPH_CMS_API_URL;
export const GRAPH_CMS_TOKEN = process.env.NEXT_PUBLIC_GRAPH_CMS_TOKEN;
export const GRAPH_CMS_CLIENT_NAME = 'graphCMS';
export const otherLabel = () => t`Jiný`;

export const regions = [
  { label: 'Hlavní město Praha', value: 'Hlavní město Praha' },
  { label: 'Středočeský kraj', value: 'Středočeský kraj' },
  { label: 'Jihočeský kraj', value: 'Jihočeský kraj' },
  { label: 'Plzeňský kraj', value: 'Plzeňský kraj' },
  { label: 'Karlovarský kraj', value: 'Karlovarský kraj' },
  { label: 'Ústecký kraj', value: 'Ústecký kraj' },
  { label: 'Liberecký kraj', value: 'Liberecký kraj' },
  { label: 'Královéhradecký kraj', value: 'Královéhradecký kraj' },
  { label: 'Pardubický kraj', value: 'Pardubický kraj' },
  { label: 'Kraj Vysočina', value: 'Kraj Vysočina' },
  { label: 'Jihomoravský kraj', value: 'Jihomoravský kraj' },
  { label: 'Olomoucký kraj', value: 'Olomoucký kraj' },
  { label: 'Zlínský kraj', value: 'Zlínský kraj' },
  { label: 'Moravskoslezský kraj', value: 'Moravskoslezský kraj' },
];

export const skills = () => [
  { label: t`Finance`, value: 'Finance' },
  { label: t`Grafika`, value: 'Grafika' },
  { label: t`HR`, value: 'HR' },
  { label: t`Investice`, value: 'Investice' },
  { label: t`Marketing`, value: 'Marketing' },
  { label: t`Obchod`, value: 'Obchod' },
  { label: t`Produktový management`, value: 'Produktový management' },
  { label: t`Právo`, value: 'Právo' },
  { label: t`Management`, value: 'Management' },
  { label: t`Networking`, value: 'Networking' },
  { label: t`Programování (Backend)`, value: 'Programování (Backend)' },
  { label: t`Programování (Frontend)`, value: 'Programování (Frontend)' },
  { label: t`UX/UI Design`, value: 'UX/UI Design' },
  { label: t`Zákaznická podpora`, value: 'Zákaznická podpora' },
];

export const projectPhaseWithLabels = () => [
  { label: t`Zatím nemám nápad, ale chci ho najít`, value: '0' },
  { label: t`Mám nápad a chci na něm zapracovat`, value: '1' },
  { label: t`Mám prototyp, který je potřeba vyzkoušet`, value: '2A' },
  { label: t`MVP již mám a chci ho vylepšit`, value: '2B' },
  { label: t`Produkt mám, ale zatím žádný zákazník`, value: '3A' },
  {
    label: t`Založil jsem startup a chci z něj dostat to nejlepší`,
    value: '4',
  },
];

export const industry = [
  { label: 'Advertising', value: 'Advertising' },
  { label: 'AgTech', value: 'AgTech' },
  { label: 'AI', value: 'AI' },
  { label: 'Analytics', value: 'Analytics' },
  { label: 'AR / VR', value: 'AR / VR' },
  { label: 'Biotech', value: 'Biotech' },
  { label: 'Blockchain', value: 'Blockchain' },
  { label: 'Cars / autonomous vehicles', value: 'Cars / autonomous vehicles' },
  { label: 'Chemicals', value: 'Chemicals' },
  { label: 'Clean tech / environment', value: 'Clean tech / environment' },
  { label: 'Cloud infrastructure', value: 'Cloud infrastructure' },
  { label: 'Consumer health & fitness', value: 'Consumer health & fitness' },
  { label: 'Consumer products', value: 'Consumer products' },
  { label: 'Cosmetics', value: 'Cosmetics' },
  { label: 'Cryptocurrency', value: 'Cryptocurrency' },
  { label: 'Data services', value: 'Data services' },
  { label: 'Developer tools', value: 'Developer tools' },
  { label: 'Distributed workforce', value: 'Distributed workforce' },
  { label: 'E-commerce', value: 'E-commerce' },
  { label: 'Education', value: 'Education' },
  { label: 'Energy tech', value: 'Energy tech' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Entertainment & sports', value: 'Entertainment & sports' },
  { label: 'Fashion', value: 'Fashion' },
  { label: 'Fintech', value: 'Fintech' },
  { label: 'Food and beverage', value: 'Food and beverage' },
  { label: 'Games', value: 'Games' },
  { label: 'Gaming / eSports', value: 'Gaming / eSports' },
  { label: 'Govtech', value: 'Govtech' },
  { label: 'Hardware', value: 'Hardware' },
  { label: 'Health & hospital services', value: 'Health & hospital services' },
  { label: 'Health IT', value: 'Health IT' },
  { label: 'Human capital', value: 'Human capital' },
  { label: 'Impact', value: 'Impact' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'IoT', value: 'IoT' },
  { label: 'Local commerce', value: 'Local commerce' },
  { label: 'Lodging / hospitality', value: 'Lodging / hospitality' },
  { label: 'Logistics', value: 'Logistics' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Marketing automation', value: 'Marketing automation' },
  { label: 'Marketplaces', value: 'Marketplaces' },
  { label: 'Material science', value: 'Material science' },
  { label: 'Media / content', value: 'Media / content' },
  { label: 'Medical devices', value: 'Medical devices' },
  { label: 'Messaging', value: 'Messaging' },
  { label: 'Network infrastructure', value: 'Network infrastructure' },
  { label: 'Parenting / families', value: 'Parenting / families' },
  { label: 'Payments', value: 'Payments' },
  { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
  { label: 'Real estate / proptech', value: 'Real estate / proptech' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Robotics', value: 'Robotics' },
  { label: 'SaaS', value: 'SaaS' },
  { label: 'Sales and CRM', value: 'Sales and CRM' },
  { label: 'Security', value: 'Security' },
  { label: 'Semiconductors', value: 'Semiconductors' },
  { label: 'SMB software', value: 'SMB software' },
  { label: 'Social commerce', value: 'Social commerce' },
  { label: 'Social mobile', value: 'Social mobile' },
  { label: 'Space', value: 'Space' },
  { label: 'Travel', value: 'Travel' },
];
