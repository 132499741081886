import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { GlobalStyle } from '../common/styles/globalStyles';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ga } from '../common/utils/ga';
import { isProduction } from '../common/utils/helpers';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages as csMessages } from '../locales/cs';
import { messages as enMessages } from '../locales/en';
import { theme } from '../common/styles/muiTheme';
import { useApollo } from '../apollo/apollo-client';
import { SnackProvider } from '../common/containers/SnackProvider';
import { AppHead } from '../common/components/AppHead';
import { WhiteLabelProvider } from '../common/containers/WhitelabeProvider';

const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);

  i18n.load({
    en: enMessages,
    cs: csMessages,
  });
  i18n.activate('cs');

  return (
    <>
      <AppHead />

      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            {isProduction && <GATracker />}

            <GlobalStyle />

            <I18nProvider i18n={i18n}>
              <SnackProvider>
                <WhiteLabelProvider>
                  <Component {...pageProps} />
                </WhiteLabelProvider>
              </SnackProvider>
            </I18nProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

// FIXME: move this inside app, it will break apollo, => update APollo handling = remove HOC for hook

const GATracker = () => {
  // TODO: if prod then
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return null;
};

export default App;
