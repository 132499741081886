import { gql } from '@apollo/client';

export const GET_WHITELABEL_CONFIG = gql`
  query getWhitelabelConfig($client: String) {
    whitelabel(where: { client: $client }) {
      client
      defaultLanguage
      communityLink
      communityLinkLabel
      logo {
        height
        url
        width
      }
    }
  }
`;
