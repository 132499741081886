import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { concatPagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { getStoredAccessToken } from '../common/utils/authHelpers';
import {
  GRAPH_CMS_CLIENT_NAME,
  GRAPH_CMS_TOKEN,
  GRAPH_CMS_API_URL,
} from '../common/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const GRAPHQL_URL = `${process.env.NEXT_PUBLIC_API_URL}/api/graphql`;
const PRISMIC_API = 'https://startupbox.prismic.io/graphql';

const prismicLink = from([
  setContext(async (_, prevState) => {
    return {
      headers: {
        ...prevState.headers,
        // authorization: `Bearer ${GraphCMSToken}`,
      },
    };
  }),

  new HttpLink({
    uri: PRISMIC_API,
  }),
]);

const graphCmsLink = from([
  setContext(async (_, prevState) => {
    return {
      headers: {
        ...prevState.headers,
        authorization: `Bearer ${GRAPH_CMS_TOKEN}`,
      },
    };
  }),

  new HttpLink({
    uri: GRAPH_CMS_API_URL,
  }),
]);

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
});

const authLink = setContext(async (_, prevState) => {
  return {
    headers: {
      ...prevState.headers,
      authorization: getStoredAccessToken()
        ? `Bearer ${getStoredAccessToken()}`
        : '',
    },
  };
});

const normalLink = from([
  authLink,
  // errorLink,
  // retryLink,
  httpLink,
]);

const link = ApolloLink.split(
  (operation) => operation.getContext().clientName === GRAPH_CMS_CLIENT_NAME,
  // the string "third-party" can be anything you want,
  // we will use it in a bit
  graphCmsLink, // <= apollo will send to this if clientName is "third-party"
  normalLink // <= otherwise will send to this
);

let apolloClient;

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
