import React, { FC } from 'react';
import Head from 'next/head';
import { t } from '@lingui/macro';

type Props = {
  title?: string;
  description?: string;
  url?: string;
};

export const AppHead: FC<Props> = ({ title, description, url }) => {
  const titleContent =
    title || t`Rozjeď svůj STARTUP i bez nápadu | online aplikace Startupbox`;
  const descriptionContent =
    description ||
    t`Vyzkoušej ZDARMA online aplikaci Startupbox a rozjeď svůj projekt. Poradíme, co je důležité i BEZ nápadu a jak ho následně najít. Napojíme tě na investory.`;
  const image = '/meta_image.png';
  const urlContent = url || 'https://startupbox.app/';

  return (
    <Head>
      <meta name="viewport" content="initial-scale=1, width=device-width" />

      <link rel="icon" href="/favicon.ico" />

      {/*<!-- Primary Meta Tags -->*/}
      <meta key="title" name="title" content={titleContent} />
      <meta key="description" name="description" content={descriptionContent} />

      {/*<!-- Open Graph / Facebook --/>*/}
      <meta property="og:type" content="website" />
      <meta key="og:url" property="og:url" content={urlContent} />
      <meta key="og:title" property="og:title" content={titleContent} />
      <meta
        key="og:description"
        property="og:description"
        content={descriptionContent}
      />
      <meta key="og:image" property="og:image" content={image} />
      {/*<!-- Twitter -->*/}

      <meta property="twitter:card" content="summary_large_image" />
      <meta key="twitter:url" property="twitter:url" content={urlContent} />
      <meta
        key="twitter:title"
        property="twitter:title"
        content={titleContent}
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={descriptionContent}
      />
      <meta key="twitter:image" property="twitter:image" content={image} />
    </Head>
  );
};
