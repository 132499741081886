import React, { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GRAPH_CMS_CLIENT_NAME } from '../constants';
import { GET_WHITELABEL_CONFIG } from '../../apollo/cms/GraphCMS/getWhitelabelConfig';
import {
  getWhitelabelConfig,
  getWhitelabelConfigVariables,
} from '../../apollo/cms/GraphCMS/__generatedTypes';
import nookies from 'nookies';
import { isProduction } from '../utils/helpers';
import { i18n } from '@lingui/core';

export type CommunityLink = {
  url: string;
  label: string;
} | null;

type WhiteLabelContext = {
  logo?: string;
  loading: boolean;
  domainName: string;
  communityLink: CommunityLink;
};
type UseWhiteLabelContext = () => WhiteLabelContext;

const WhiteLabelContext = React.createContext<WhiteLabelContext>(
  {} as WhiteLabelContext
);

export const useWhiteLabelContext: UseWhiteLabelContext = () =>
  useContext(WhiteLabelContext);

export const WhiteLabelProvider: FC = ({ children }) => {
  const [subdomain, setSubdomain] = useState('');

  useEffect(() => {
    const subdomainGetter = window.location.host.split('.')[1]
      ? window.location.host.split('.')[0]
      : undefined;

    if (isProduction) {
      setSubdomain(subdomainGetter);
    }

    if (!isProduction && subdomainGetter?.includes('test')) {
      setSubdomain(subdomainGetter);
    }

    nookies.set({}, 'domain', window.location.href);
  }, []);

  const { data, loading } = useQuery<
    getWhitelabelConfig,
    getWhitelabelConfigVariables
  >(GET_WHITELABEL_CONFIG, {
    skip: !subdomain,
    variables: { client: subdomain },
    context: {
      clientName: GRAPH_CMS_CLIENT_NAME,
    },
  });

  if (data?.whitelabel?.defaultLanguage) {
    i18n.activate(data?.whitelabel?.defaultLanguage);
  }

  return (
    <WhiteLabelContext.Provider
      value={{
        logo: data?.whitelabel?.logo.url,
        loading,
        domainName: subdomain || 'default',
        communityLink: data?.whitelabel
          ? {
              url: data?.whitelabel.communityLink,
              label: data?.whitelabel.communityLinkLabel,
            }
          : null,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  );
};
